import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import 'lazysizes';
import "./scroll-to-top";
import "./floating-cart";

if(import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Setup Alpine.js
window.Alpine = Alpine
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.start();