const floatingCartButton = document.querySelector("#floatingCart") as HTMLButtonElement;

if(floatingCartButton) {
    document.addEventListener("scroll", (e) => {
        const pos = document.documentElement.scrollTop || document.body.scrollTop || 0

        if(pos > 500) {
            floatingCartButton.style.display = "flex";
        } else {
            floatingCartButton.style.display = "none";
        }
    });
}